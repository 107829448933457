export default defineNuxtRouteMiddleware((to, from) => {
  const cartStore = useCartStore();

  if (cartStore.items.length === 0) {
    return navigateTo({ name: 'menu' });
  }

  const authStore = useAuthStore();

  if (from.name !== 'login') {
    if (!authStore.isAuthenticated && !authStore.isGuest) {
      return navigateTo({
        name: 'login',
        query: { redirect: to.path }
      });
    }
  }
});
